import styled from 'styled-components';
import { Link } from 'gatsby';

const PageNavigation = styled.div`
	display: flex;
	justify-content: space-between;
`;
const NavLink = styled(Link)`
	font-size: 1rem;
	display: flex;
	align-items: center;
`;

export { PageNavigation, NavLink };
