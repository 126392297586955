import React, { Component } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
// import PostIcons from "../components/post-icons"
// import Img from "gatsby-image"
import Layout from '../components/layout';

import { PageNavigation, NavLink } from '../components/styles/post';
// import { rhythm } from "../utils/typography"

class PostTemplate extends Component {
	render() {
		const post = this.props.data.wordpressPost;
		const { next, previous } = this.props.pageContext;

		return (
			<Layout>
				<h1 dangerouslySetInnerHTML={{ __html: post.title }} />
				{Parser(post.content.replace(/nessatoye\.com/g, 'data.nessatoye.com'))}
				{(next || previous) && (
					<PageNavigation>
						{previous ? (
							<NavLink to={`/blog/${previous.path}`}>
								<FaChevronLeft /> {previous.title}
							</NavLink>
						) : null}
						{next ? (
							<NavLink to={`/blog/${next.path}`}>
								{next.title}
								<FaChevronRight />
							</NavLink>
						) : null}
					</PageNavigation>
				)}
			</Layout>
		);
	}
}

PostTemplate.propTypes = {
	data: PropTypes.object.isRequired,
};

export default PostTemplate;

export const postQuery = graphql`
	query($id: String!) {
		wordpressPost(id: { eq: $id }) {
			title
			content
		}
	}
`;
